<template>
  <v-container fluid class="text-center">
    <v-navigation-drawer app right v-model="drawer" class="app-sidemenu" temporary width="300">
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10" class="container-padding">
          <h2 class="titulo text-center">Filtros</h2>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.nombre_persona" label="Nombre del cliente" prepend-icon="mdi-account" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-autocomplete v-model="filters.grupos" label="Grupo" :items="group" item-text="nombre" item-value="nombre" prepend-icon="mdi-account-group" :disabled="loading" multiple small-chips outlined dense required>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0" small>
                <span>{{ item.nombre }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ filters.grupos.length - 1 }} más)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <v-autocomplete v-model="filters.estado_solicitud" label="Estado de solicitud" :items="status" prepend-icon="mdi-file" :disabled="loading" outlined dense required></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12">
          <Date-Picker v-model="filters.fecha_creacion_inicio" label="Fecha inicio de creación" />
        </v-col>
        <v-col cols="12" md="12">
          <Date-Picker v-model="filters.fecha_creacion_fin" label="Fecha fin de creación" />
        </v-col>
        <v-divider />
        <v-col cols="12" md="12">
          <h2 class="primary--text">Direccion de cliente</h2>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.calle" label="Calle" prepend-icon="mdi-home-outline" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.colonia" label="Colonia" prepend-icon="mdi-home-group" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.estado" label="Estado" prepend-icon="mdi-city" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model="filters.direccion_persona.pais" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field v-model.number="filters.direccion_persona.cp" label="Código postal" prepend-icon="mdi-pound" :disabled="loading" type="number" outlined dense required></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <Basic-Btn text="Reiniciar" block color="secondary" icon="mdi-filter-remove" @click="resetFilters()" :disabled="loading"/>
          <Basic-Btn text="Buscar" block color="primary" icon="mdi-magnify" @click="getClient()" :loading="loading"/>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <Loader :loading="loading" />
    <Table-Container title="Clientes" cardTextFooter>
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="getClient()" :loading="loading" />
      </template>
      <template v-slot:search>
        <Basic-Btn text="Filtros" color="primary" icon="mdi-filter" @click="drawer = !drawer" :loading="loading"  />
      </template>
      <v-data-table :headers="headers" :items="items" hide-default-footer :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="20" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.fecha_creacion`]="{ item }">
          {{ dateFormat(item.fecha_creacion) }}
        </template>
        <template v-slot:[`item.persona.nombre`]="{ item }">
          {{ item.persona.nombre + " " + item.persona.apellido_paterno + " " + item.persona.apellido_materno }}
        </template>
        <template v-slot:[`item.solicitudes_prestamos`]="{ item }">
          {{ returnRequestState(item.solicitudes_prestamos) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item.idcliente, item.persona)">mdi-eye</v-icon></template><span>Ver detalle</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
      <template v-slot:text>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-select v-model="listPage" label="Pagina" :items="[20, 50]" outlined dense></v-select>
          </v-col>
          <v-col cols="2" class="container-padding">
            <v-pagination v-model="page" :length="limitPage" :total-visible="10"></v-pagination>
          </v-col>
        </v-row>
      </template>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, FillterList, DatePicker, } from "@/components";
import services from "@/utils/services";
import moment from "moment";

export default {
  name: "client",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    FillterList,
    DatePicker,
  },
  data: () => ({
    drawer: false,
    group: null,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    loading: false,
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idcliente" },
      { text: "Clave de elector", align: "left", sortable: true, value: "persona.clave_elector" },
      { text: "Nombre", align: "left", sortable: true, value: "persona.nombre" },
      { text: "Telefono", align: "left", sortable: true, value: "persona.telefono_movil" },
      { text: "Estado civil", align: "left", sortable: true, value: "persona.estado_civil" },
      { text: "Ocupación", align: "left", sortable: true, value: "persona.ocupacion" },
      { text: "Estado de ultimo prestamo", align: "left", sortable: true, value: "solicitudes_prestamos" },
      { text: "Facha de creacion", align: "left", sortable: true, value: "fecha_creacion" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    filters: {
      idcliente: '',
      nombre_persona: '',
      grupos: [],
      estado_solicitud: '',
      fecha_creacion_inicio: '',
      fecha_creacion_fin: '',
      direccion_persona: {
        calle: '',
        colonia: '',
        estado: '',
        municipio: '',
        pais: '',
        cp: '',
      },
    },
    items: [],
    status: [],
    group: [],
    page: 1,
    limitPage: 1,
    listPage: 20,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
    page() {
      this.getRequest();
    },
    listPage() {
      this.getRequest();
    },
  },
  mounted() {
    this.status = services.statusType;
    this.getClient();
    this.getGroup();
  },
  methods: {
    getClient() {
      this.loading = true;
      let sendFilters = {}
      for (let key in this.filters) {
        if (this.filters[key]) {
          sendFilters[key] = this.filters[key];
        }
      }
      sendFilters['direccion_persona'] = {}
      for (let key2 in this.filters['direccion_persona']) {
        if (this.filters['direccion_persona'][key2]) {
          sendFilters['direccion_persona'][key2] = this.filters['direccion_persona'][key2];
        }
      }
      let request = {
        params: {
          page_size: this.listPage,
          page_number: this.page,
          filters: sendFilters,
        },
      };
      this.axios.get(services.routes.client + "/", request)
        .then((response) => {
          let data = response.data.data;
          this.items = data.values;
          this.limitPage = data.pages;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getGroup() {
      this.axios.get(services.routes.group)
        .then((response) => {
          this.group = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    view(id, person) {
      this.$router.push({
        path: "/detalle-cliente/" + id,
        query: person,
      });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    returnRequestState(info) {
      let i = info.length;
      let msg = "Sin solicitudes";
      if (i > 0) {
        msg = info[0].estatus_solicitudes_prestamos[0] ? info[0].estatus_solicitudes_prestamos[0].estatus.nombre : "No disponible";
        msg = msg[0].toUpperCase() + msg.slice(1);
      }
      return msg;
    },
    resetFilters() {
      this.filters = {
        idcliente: '',
        nombre_persona: '',
        grupos: [],
        estado_solicitud: '',
        fecha_creacion_inicio: '',
        fecha_creacion_fin: '',
        direccion_persona: {
          calle: '',
          colonia: '',
          estado: '',
          municipio: '',
          pais: '',
          cp: '',
        },
      }
    },
  },
};
</script>

<style scoped>
  .app-sidemenu {
    z-index: 99;
  }
</style>
